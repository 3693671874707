import Sidebar from '../Sidebar';
import Topbar from '../Topbar';
import { useState, useEffect } from 'react';
import active from "../../assets/images/progress.png"
import overdue from "../../assets/images/active.png"
import closed from "../../assets/images/closed.png"
import search from "../../assets/images/search.png"
import pendingdot from "../../assets/images/pendingDot.png"
import activedot from "../../assets/images/activeDot.png"
import closeddot from "../../assets/images/closedDot.png"
import tododot from "../../assets/images/todoDot.png"
import initiateddot from "../../assets/images/initiatedDot.png"
import rupee from "../../assets/images/rupee.png"
import {Row,Col,Input} from "reactstrap"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import SmsTwoToneIcon from '@mui/icons-material/SmsOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import jwt_decode from 'jwt-decode';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useLocation,useNavigate } from 'react-router-dom';
import axiosInstance from '../../helpers/axios';
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'
import { userActions } from '../../actions/user.actions';
import PaginationTable from '../status/PaginationTable';
import clear from "../../assets/images/clear.png";
import "./Loans.css"
import { CoPresentOutlined, SettingsApplications } from '@mui/icons-material';
const LoanTable = ()=>{
  const state = useSelector((state) => state?.Loans?.res);
  const countstate = useSelector((state) => state?.LoanDetails?.res);

  const navigate = useNavigate();
 
  const dispatch = useDispatch();
  const [totalFetchRows, setTotalFetchRows] = useState(null);
  const [currentPage, setcurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(15);
  const [loanId, setloanId] = useState(null);
  const [filterCustomerName, setFilterCustomerName] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const [searchText, setSearchText] = useState("")
  const [pos, setPos] = useState("")
  const [id,setId]=useState(null)

  const [selectedValue, setSelectedValue] = useState("");
  
  const user = sessionStorage.getItem('user');
  
  const decode=jwt_decode(user)
    const [tableData, setTableData] = useState([]);
    const [count, setCount] = useState([]);
    const [status, setStatus] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const url = `/loan-repayment/loans?perPage=${rowsPerPage}&pageNo=${currentPage}`
    const customerNameField=document.getElementById("customerName")?.value
    const urlUsers = `/loan-repayment/search?searchField=${customerNameField?customerNameField.length>=5?customerNameField:null:null}`;
    
    const countUrl = `/loans/count`
    const clearDateFilter = () => {
      window.location.reload()
      const url = `/loan-repayment/loans?perPage=${rowsPerPage}&pageNo=${currentPage}`
      setSearchText("")

      axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
  
        setTableData(res?.data?.data?.data);
        setcurrentPage(res?.data?.data?.pagination.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination.totalRows);
        setrowsPerPage(res?.data?.data?.pagination.perPage);  
        const loadingTimeout = setTimeout(() => {
          setIsLoading(false);
        }, 300);
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
    };
   
    useEffect(() => {
      if(filterCustomerName){
        console.log("inside  if")
        handleCustomerChange()
      }
  else if(searchText === "" || filterCustomerName === ""){
    console.log("inside else")
    setIsLoading(true);
    axiosInstance.get(url,{
      headers:{
        Authorization:`Bearer ${user}`
      }
    }).then((res)=>{
  
      setTableData(res?.data?.data?.data)
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage); 
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }).catch((error)=>{
      alert(error?.response?.data?.message)
    })
  }

    }, [customerNameField]);

   useEffect(()=>{
    let queryParams = {}

      if(id){
        queryParams.instituteId = id
      }
      
     
    axiosInstance.get("/loans/count",{params:queryParams,
      headers:{
        Authorization:`Bearer ${user}`
      }
    }).then((res) => {
      setCount(res.data.data)

      
    })
    .catch((error)=>console.log(error))


    
   },[countUrl])

   const urlPos = `/analytics`

   useEffect(()=>{
    axiosInstance.get(urlPos,{
      headers:{
        Authorization:`Bearer ${user}`
      }
    }).then((res) => {
      setPos(res?.data?.data?.totalPos)

      
    })
    .catch((error)=>console.log(error))


    
   },[countUrl])
      
   
   

    
    const paginate = (pageNumber) => {
      
      setcurrentPage(pageNumber);
      let queryParams = {}

      if(status){
        queryParams.status = status
      }
      
      if(id){
        queryParams.instituteId = id
      }
      
      if(rowsPerPage && currentPage){
        queryParams.perPage = rowsPerPage;  
        queryParams.pageNo = pageNumber
      }
  
      axiosInstance.get('/loan-repayment/loans',{
        params:queryParams
      , 
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
  
        setTableData(res?.data?.data?.data);
        setcurrentPage(res?.data?.data?.pagination.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination.totalRows);
        setrowsPerPage(res?.data?.data?.pagination.perPage);  
        const loadingTimeout = setTimeout(() => {
                setIsLoading(false);
              }, 300);
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
    };

  const handleLoanProfile = (loanId,legacyLoanId)=>{
    sessionStorage.setItem("loanid",loanId)
    sessionStorage.setItem("legacyLoanId",legacyLoanId)
    const urlUsers = `/loan-repayment/profile?loanId=${sessionStorage.getItem("loanid")}`;
    const callDetailsUrl = `/call-history/details?loanId=${sessionStorage.getItem("loanid")}`;
    const urlGetReminder=`/call-history/reminder-details?loanId=${sessionStorage.getItem("loanid")}`
    const emihistoryUrl=`/emis/for-loan?loanId=${sessionStorage.getItem("loanid")}`

    dispatch(userActions.fetchCallDetails(callDetailsUrl, user),[])
    dispatch(userActions.fetchLoanDetails(urlUsers, user),[])
    dispatch(userActions.getReminder(urlGetReminder))
    dispatch(userActions.getEmiHistory(emihistoryUrl,user))

  
    const newWindow = window.open("/Loanprofile", "_blank");  }
    
  const handleButtonClick = (event) => {
    const status = event.target.value; 
 
    setStatus(status)
    setIsLoading(true);
    let queryParams = {}

    if(status){
      queryParams.status = status
    }
    
    if(id){
      queryParams.instituteId = id
    }
    
    if(rowsPerPage && currentPage){
      queryParams.perPage = rowsPerPage;
      queryParams.pageNo = 1
    }

    axiosInstance.get('/loan-repayment/loans',{
      params:queryParams
    , 
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {

      setTableData(res?.data?.data?.data);
      setcurrentPage(res?.data?.data?.pagination.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination.totalRows);
      setrowsPerPage(res?.data?.data?.pagination.perPage);  
      const loadingTimeout = setTimeout(() => {
              setIsLoading(false);
            }, 300);
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });

    // if((status === "3" || status === "2" || status === "1") && searchText === ""){
      

    //   const url = `/loan-repayment/loans?status=${status}&perPage=${rowsPerPage}&pageNo=${1}`


    //   axiosInstance.get(url,{
    //     headers:{
    //       Authorization:`Bearer ${user}`
    //     }
    //   }).then((res)=>{

    //    setTableData(res?.data?.data?.data)
    //    setcurrentPage(res?.data?.data?.pagination?.currentPage);
    //     setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
    //     setrowsPerPage(res?.data?.data?.pagination?.perPage); 
    //     const loadingTimeout = setTimeout(() => {
    //       setIsLoading(false);
    //     }, 300);
    //   })
    // }else if((status === "3" || status === "2" || status === "1") && searchText !== ""){
    //   const url = `/loan-repayment/loans?instituteId=${id}&status=${status}&perPage=${rowsPerPage}&pageNo=${1}`


    //   axiosInstance.get(url,{
    //     headers:{
    //       Authorization:`Bearer ${user}`
    //     }
    //   }).then((res)=>{

    //    setTableData(res?.data?.data?.data)
    //    setcurrentPage(res?.data?.data?.pagination?.currentPage);
    //     setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
    //     setrowsPerPage(res?.data?.data?.pagination?.perPage); 
    //     const loadingTimeout = setTimeout(() => {
    //       setIsLoading(false);
    //     }, 300);
    //   })
    // }else if(status === "all" && searchText !== ""){
    //   const url = `/loan-repayment/loans?instituteId=${id}&perPage=${rowsPerPage}&pageNo=${1}`


    //   axiosInstance.get(url,{
    //     headers:{
    //       Authorization:`Bearer ${user}`
    //     }
    //   }).then((res)=>{

    //    setTableData(res?.data?.data?.data)
    //    setcurrentPage(res?.data?.data?.pagination?.currentPage);
    //     setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
    //     setrowsPerPage(res?.data?.data?.pagination?.perPage); 
    //     const loadingTimeout = setTimeout(() => {
    //       setIsLoading(false);
    //     }, 300);
    //   })
    // }
    // else{

    //   const url = `/loan-repayment/loans?perPage=${rowsPerPage}&pageNo=${1}`
     
    //   axiosInstance.get(url,{
    //     headers:{
    //       Authorization:`Bearer ${user}`
    //     }
    //   }).then((res)=>{
   
    //    setTableData(res?.data?.data?.data)
    //    setcurrentPage(res?.data?.data?.pagination?.currentPage);
    //     setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
    //     setrowsPerPage(res?.data?.data?.pagination?.perPage); 
    //     const loadingTimeout = setTimeout(() => {
    //       setIsLoading(false);
    //     }, 300);
    //   })
    // }
    
   
  };
  const getInsituteInfo=(text)=>{
    
    const urlGetInstitute=`/institute/info/name?name=${text}`;
      
    if(text.length>=4)
    {
      axiosInstance.get(urlGetInstitute,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      }).then((res) => {
        console.log("text",res?.data?.data[0]?.id)
        setId(res?.data?.data[0]?.id)
      });
  
   
    }
 
  }
  const handleCustomerChange = () =>{

      axiosInstance.get(urlUsers,{
        headers:{
          Authorization:`Bearer ${user}`
      }}).then((res) => {
        
    
          console.log("res",res?.data?.data)
          setTableData(res?.data?.data);
         
          // setcurrentPage(res.data.data.pagination.currentPage);
          // setTotalFetchRows(res.data.data.pagination.totalRows);
          // setrowsPerPage(res.data.data.pagination.perPage);
        
      });
    
  }
  const [searchClicked,setSearchClicked] = useState(false)
  const [legacyloanId,setLegacyLoanId] = useState(null)
  const handleInputChange = (event) => {
    setSearchClicked(!searchClicked)
    if(searchText.length >= 4)
      {
      let queryParams = {}

      
      if(id){
        queryParams.instituteId = id
      }
      axiosInstance.get('/loan-repayment/loans',{
        params:queryParams,
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
  
        setTableData(res?.data?.data?.data);
        setcurrentPage(res?.data?.data?.pagination.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination.totalRows);
        setrowsPerPage(res?.data?.data?.pagination.perPage);  
       
      })
      .catch((error) => {
        // alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
      let queryParamss = {}

      if(id){
        queryParamss.instituteId = id
      }
      
     
    axiosInstance.get("/loans/count",{params:queryParamss,
      headers:{
        Authorization:`Bearer ${user}`
      }
    }).then((res) => {
      setCount(res.data.data)

      
    })
    .catch((error)=>console.log(error))


    }else if (legacyloanId){
      let queryParams = {}
      if(legacyloanId){
        queryParams.loanId = legacyloanId
      }
  
      axiosInstance.get('/loan-repayment/loan-id-search',{
        params:queryParams,
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
  
        setTableData(res?.data?.data);
        // setcurrentPage(res?.data?.data?.pagination.currentPage);
        // setTotalFetchRows(res?.data?.data?.pagination.totalRows);
        // setrowsPerPage(res?.data?.data?.pagination.perPage);  
       
      })
      .catch((error) => {
        // alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
    }

  //   if(searchText.length >= 4){
  //     console.log("inside first if")
  //     const urlUsers = `/loan-repayment/loans?instituteId=${id}&perPage=${rowsPerPage}&pageNo=${1}`
 
  //   axiosInstance.get(urlUsers, {
  //     headers: {
  //       Authorization: `Bearer ${user}`,
  //     },
  //   })
  //   .then((res) => {
  //     console.log("filtering",res)
  //     setTableData(res?.data?.data?.data);
  //     setcurrentPage(res?.data?.data?.pagination.currentPage);
  //     setTotalFetchRows(res?.data?.data?.pagination.totalRows);
  //     setrowsPerPage(res?.data?.data?.pagination.perPage);  
  //     const loadingTimeout = setTimeout(() => {
  //       setIsLoading(false);
  //     }, 300);
  //   })
  //   .catch((error) => {
  //      alert(error?.response?.data?.message);
  //   });
  // }else if((status === "3" || status === "2" || status === "1") && searchText !== ""){
  //   const url = `/loan-repayment/loans?instituteId=${id}&status=${status}&perPage=${rowsPerPage}&pageNo=${1}`


  //   axiosInstance.get(url,{
  //     headers:{
  //       Authorization:`Bearer ${user}`
  //     }
  //   }).then((res)=>{

  //    setTableData(res?.data?.data?.data)
  //    setcurrentPage(res?.data?.data?.pagination?.currentPage);
  //     setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
  //     setrowsPerPage(res?.data?.data?.pagination?.perPage); 
  //     const loadingTimeout = setTimeout(() => {
  //       setIsLoading(false);
  //     }, 300);
  //   })
  // }else if(status === "all" && searchText !== ""){
  //   const url = `/loan-repayment/loans?instituteId=${id}&perPage=${rowsPerPage}&pageNo=${1}`


  //   axiosInstance.get(url,{
  //     headers:{
  //       Authorization:`Bearer ${user}`
  //     }
  //   }).then((res)=>{

  //    setTableData(res?.data?.data?.data)
  //    setcurrentPage(res?.data?.data?.pagination?.currentPage);
  //     setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
  //     setrowsPerPage(res?.data?.data?.pagination?.perPage); 
  //     const loadingTimeout = setTimeout(() => {
  //       setIsLoading(false);
  //     }, 300);
  //   })
  // }
  // else{
  //   console.log("inside first else")
  //   setSearchText("")
  //   const urlUsers = `/loan-repayment/loans?perPage=${rowsPerPage}&pageNo=${currentPage}`
  //   axiosInstance.get(urlUsers, {
  //     headers: {
  //       Authorization: `Bearer ${user}`,
  //     },
  //   })
  //   .then((res) => {
  //     console.log("filtering",res)
  //     setTableData(res?.data?.data?.data);
  //     setcurrentPage(res?.data?.data?.pagination.currentPage);
  //     setTotalFetchRows(res?.data?.data?.pagination.totalRows);
  //     setrowsPerPage(res?.data?.data?.pagination.perPage);  
  //     const loadingTimeout = setTimeout(() => {
  //       setIsLoading(false);
  //     }, 300);
  //   })
  //   .catch((error) => {
  //     alert(error?.response?.data?.message);
  //     console.error("Error fetching filtered data:", error);
  //   });



  // }
};
const handleChange = (event) => {
  setIsLoading(true)
  setrowsPerPage(event.target.value);
  let queryParams = {}

      if(status){
        queryParams.status = status
      }
      
      if(id){
        queryParams.instituteId = id
      }
      
      if(rowsPerPage && currentPage){
        queryParams.perPage = event.target.value;
        queryParams.pageNo = 1
      }
  
      axiosInstance.get('/loan-repayment/loans',{
        params:queryParams,
      
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
  
        setTableData(res?.data?.data?.data);
        setcurrentPage(res?.data?.data?.pagination.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination.totalRows);
        setrowsPerPage(res?.data?.data?.pagination.perPage);  
        const loadingTimeout = setTimeout(() => {
                setIsLoading(false);
              }, 300);
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
};

  function formatNumberWithCommas(number) {
    return number?.toLocaleString('en-IN');
  }

  const [isOpen,setIsOpen]=useState(true)

    return(
        <div style={{display:'flex', flexDirection:'row',width:"100%",backgroundColor:'white',minHeight:'100vh'}}>
        <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
        <div style={{width:isOpen?'78%':'98%', overflow:"auto",marginLeft:isOpen?"21%":'0%',}}>
                <Topbar/>
                <h1 style={{fontFamily:'Inter-Medium',fontSize:'25px',fontWeight:'500',padding:'10px',color:'#101828'}}>All Loans</h1>

                <div >
               
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div>
                            
                            <div  style={{cursor:'pointer',backgroundColor: isActive && status === 1 ? '#D32028' : '#F9FAFB',borderColor:'#C4C4C4',borderRadius:'16px',display:'flex',alignItems:'center',height:'80px',width:'250px',justifyContent:'space-between',padding:'30px'}}>
                            <p style={{lineHeight:'25px',paddingTop:'20px'}}><span 
                                style={{color: isActive && status === 1 ? 'white' : '#D32028',fontSize:'30px',fontWeight:'600',fontFamily:'Inter-Medium'}}>{count.find((item) => item.status === 1)?.count || 0}</span>
                                <br/><span style={{fontSize:'0.9vw',fontWeight:'300',fontFamily:'Inter-Medium',color:isActive && status === 1 ? 'white' : 'black'}}>Active Loans</span></p>
                            <img style={{height:'50px',width:'50px'}} src={active}/>
                            </div>
                         
                        </div>
                        <div>
                            
                            <div  style={{cursor:'pointer',backgroundColor: isActive && status === 3 ? '#D32028' : '#F9FAFB',borderColor:'#C4C4C4',borderRadius:'16px',display:'flex',alignItems:'center',height:'80px',width:'250px',justifyContent:'space-between',padding:'30px'}}>
                            <p style={{lineHeight:'25px',paddingTop:'20px'}}><span 
                                style={{color: isActive && status === 3 ? 'white' : '#D32028',fontSize:'30px',fontWeight:'600',fontFamily:'Inter-Medium'}}>{count.find((item) => item.status === 3)?.count || 0}</span>
                                <br/><span style={{fontSize:'0.9vw',fontWeight:'300',fontFamily:'Inter-Medium',color:isActive && status === 3 ? 'white' : 'black'}}>Overdues</span></p>
                            <img style={{height:'50px',width:'50px'}} src={overdue}/>
                            </div>    
                            
                        </div>
                        <div>
                            
                            <div  style={{cursor:'pointer',backgroundColor: isActive && status === 2 ? '#D32028' : '#F9FAFB',borderColor:'#C4C4C4',borderRadius:'16px',display:'flex',alignItems:'center',height:'80px',width:'250px',justifyContent:'space-between',padding:'30px'}}>
                            <p style={{lineHeight:'25px',paddingTop:'20px'}}><span 
                                style={{color: isActive && status === 2 ? 'white' : '#D32028',fontSize:'30px',fontWeight:'600',fontFamily:'Inter-Medium'}}>{count.find((item) => item.status === 2)?.count || 0}</span>
                                <br/><span style={{fontSize:'0.9vw',fontWeight:'300',fontFamily:'Inter-Medium',color:isActive && status === 2 ? 'white' : 'black'}}>Closed</span></p>
                            <img style={{height:'50px',width:'50px'}} src={closed}/>
                            </div>
                            
                          
                        </div>
                        <div>
                          
                            <div  style={{cursor:'pointer',backgroundColor:  '#F9FAFB',borderColor:'#C4C4C4',borderRadius:'16px',display:'flex',alignItems:'center',height:'80px',width:'250px',justifyContent:'space-between',padding:'30px'}}>
                            <p style={{lineHeight:'25px',paddingTop:'20px'}}><span 
                                style={{color:'#D32028',fontSize:'23px',fontWeight:'600',fontFamily:'Inter-Medium'}}>₹{formatNumberWithCommas(Math.floor(pos))}</span>
                                <br/><span style={{fontSize:'0.9vw',fontWeight:'300',fontFamily:'Inter-Medium',color: 'black'}}>POS</span></p>
                            <img style={{height:'50px',width:'50px'}} src={rupee}/>
                            </div>
                            
                            
                        </div>
                    </div>
            
                {/* <Input  style={{border:'1px solid #D0D5DD',width:'50%',height:'40px',borderRadius:'10px',marginTop:'20px',fontFamily: "Inter-Medium"}}type='text' placeholder='Enter at least 5 characters of Applicant Name or Phone number'/>  */}
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'10px'}}>
                <Input id="customerName" onChange={(event) => {setFilterCustomerName(event.target.value);handleCustomerChange(event)}}
              type="text"
              placeholder="Enter at least 5 characters of Customer Name"
              className="placeholder-style"
              style={{fontSize: '14px',paddingLeft: '25px',marginLeft:'5px',height: '23px',width:'20%',fontFamily: 'Inter-Medium',backgroundImage: `url(${search})`,backgroundRepeat: 'no-repeat',backgroundPosition: '5px center',backgroundSize: '15px 15px',paddingRight: '5px', borderColor:'#cccccc',borderStyle:'solid',borderRadius:"8px",borderWidth:'1px',height:'35px',marginTop:'11px'
              }}
            />
            <Input  onChange={(event) => {
                getInsituteInfo(event.target.value);setSearchText(event.target.value);
                if (event.target.value === '') {
                  setSearchText("")
                  clearDateFilter(); 
                }
              }} 
              type="text"
              placeholder="Institute Name"
              className="placeholder-style"
              style={{fontSize: '14px',paddingLeft: '25px',marginLeft:'5px',height: '23px',width:'15%',fontFamily: 'Inter-Medium',backgroundImage: `url(${search})`,backgroundRepeat: 'no-repeat',backgroundPosition: '5px center',backgroundSize: '15px 15px',paddingRight: '5px', borderColor:'#cccccc',borderStyle:'solid',borderRadius:"8px",borderWidth:'1px',height:'35px',marginTop:'11px'
              }}
            />
            <Input  onChange={(event) => {
                setLegacyLoanId(event.target.value);
                if (event.target.value === '') {
                  setSearchText("")
                  clearDateFilter(); 
                }
              }} 
              type="text"
              placeholder="Legacy Loan Id"
              className="placeholder-style"
              style={{fontSize: '14px',paddingLeft: '25px',marginLeft:'5px',height: '23px',width:'15%',fontFamily: 'Inter-Medium',backgroundImage: `url(${search})`,backgroundRepeat: 'no-repeat',backgroundPosition: '5px center',backgroundSize: '15px 15px',paddingRight: '5px', borderColor:'#cccccc',borderStyle:'solid',borderRadius:"8px",borderWidth:'1px',height:'35px',marginTop:'11px'
              }}
            />
            <Input type="select" id="page" style={{color:'#667085',fontSize:'14px',fontFamily:'Inter-Medium',width:'15%',marginTop:'11px'}} onChange={(event)=>handleChange(event)}>
            <option value="15" >Per Page 15</option>
            <option value="50">Per Page 50</option>
            <option value="100">Per Page 100</option>
            <option value="500">Per Page 500</option>

          </Input>
          {searchClicked === true ? (
            <div style={{backgroundColor:'#D22129',display:'flex',alignItems:'center',width:'130px',height:'34px',justifyContent:'space-around',borderRadius:'5px',cursor:'pointer',paddingLeft:'10px',marginTop:'10px'}} onClick={clearDateFilter}>
                      <img src={clear} style={{height:'18px'}}/>
                      <div style={{color:'white',display:'flex',alignItems:'center'}}>Clear Filter</div>
                    </div>
          ):(
<button style={{display: 'flex', alignItems: 'center',width:'130px',backgroundColor:'#D22129',height:'34px',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter-Medium",paddingLeft:'10px',marginTop:'10px'}}
              onClick={(event) =>  handleInputChange(event)}
              >
                Search Results
              </button>
          )}
          
            
                </div>
                <FormControl>
           
           <RadioGroup
             row
             aria-labelledby="demo-row-radio-buttons-group-label"
             name="row-radio-buttons-group" style={{marginTop:'10px',marginLeft:'5px'}}  value={selectedValue} onChange={(event) => {
              setSelectedValue(event.target.value)
              handleButtonClick(event);
            }}
           >
             <FormControlLabel value="" control={<Radio />}  label={<span style={{fontFamily:'Inter-Medium',color:'#667085'}}>All</span>} />
             <FormControlLabel  style={{marginLeft:'30px'}} value="1" control={<Radio />}  label={<span style={{fontFamily:'Inter-Medium',color:'#667085'}}>Active</span>}/>
             <FormControlLabel  style={{marginLeft:'30px'}} value="3" control={<Radio />} label={<span style={{fontFamily:'Inter-Medium',color:'#667085'}}>Overdue</span>} />
             <FormControlLabel
               value="2"
             
               control={<Radio />}
               label={<span style={{fontFamily:'Inter-Medium',color:'#667085'}}>Closed</span>}  style={{marginLeft:'30px'}}
             />
           </RadioGroup>
         </FormControl>
            {isLoading ? (
              <Box style={{ display: 'flex',alignItems:'center',justifyContent:'center',padding:'15%'}}>
              <CircularProgress />
            </Box>
            ):(
              <>
               {Array.isArray(tableData)?(
              <div className='tables' >
                
                  <table hover  style={{width:'100%'}}>
                  <thead className='table-heading'>
                      <tr>
                      <th style={{width:'12em',textAlign:'left',paddingLeft:'20px',borderTopLeftRadius:'8px'}}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}>Loan ID</span></th>
                      <th style={{ width:'20em',textAlign:'left' }}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',marginLeft:'10px'}}>CustName</span></th>
                      <th style={{ width:'18em',textAlign:'left' }}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',marginLeft:'10px'}}>Inst Name</span></th>
                      <th style={{ width:'8em', textAlign:'left' ,fontFamily:'Inter',fontSize:'0.9vw' ,color:'#667085'}}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',marginLeft:'10px'}}>LoanAmt</span></th>
                      <th style={{  width:'8em',textAlign:'left' ,fontFamily:'Inter',fontSize:'0.9vw' ,color:'#667085'}}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',marginLeft:'10px'}}>PaidAmt</span></th>
                      <th style={{ width:'8em',textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw' ,color:'#667085' }}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',marginLeft:'10px'}}>POS</span></th>
                      <th style={{ textAlign:'left' ,fontFamily:'Inter',fontSize:'0.9vw' ,color:'#667085',width:'10em',borderTopRightRadius:'8px'}}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',marginLeft:'20px'}}>Status</span></th>
                      </tr>
                  </thead>
                  
                      {tableData?.map((item,index) =>{
                       
                        return(
                      <tbody className='table-body'>
                          <tr className='table-row' key={index} style={{ lineHeight: '14px' }}>
                          <td onClick={()=>handleLoanProfile(item.loanId,item.legacyLoanId)} style={{cursor:'pointer', maxWidth:'12em',color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',paddingLeft:'20px' }}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#101828',fontWeight:'500'}}>{item.legacyLoanId}</span></td>
                          
                          <td style={{ maxWidth:'20em', color:'#667085'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#101828',fontWeight:'500',lineHeight:'1.5em'}}>{item?.userName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span></td>
                          <td style={{maxWidth:'18em', color:'#667085'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',fontWeight:'500',lineHeight:'1.5em',display:"block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>{item.instituteName}</span></td>
                          <td style={{ color:'#667085' }}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',fontWeight:'500'}}> {item.loanAmount ? ( <>&#8377;{formatNumberWithCommas(item.loanAmount)}</>) : ( '-')}</span></td>
                          <td style={{color:'#667085' }}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',fontWeight:'500'}}>{item.paidAmount ? ( <>&#8377;{formatNumberWithCommas(item.paidAmount)}</>) : ( '-')}</span></td>
                          <td style={{ color:'#667085'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',fontWeight:'500'}}>{item.outstandingAmount ? ( <>&#8377;{formatNumberWithCommas(item.outstandingAmount)}</>) : ( '-')}</span></td>
                          <td style={{ maxWidth: '10em',color:'#667085'}}>
                            <span>
                            {item.status ? (
                              item.status === 1? (
                                <span style={{width:'5em',paddingLeft:'10px',backgroundColor:'#ECFDF3',color:'#027A48',borderRadius:'1em',fontWeight:'600',display:'flex',alignItems:'center',height:'30px'}}>
                                  <img src={activedot} /> <span style={{marginLeft:'5px'}}>Active</span>
                                </span>
                              ) : item.status === 2 ? (
                                <span style={{width:'6em',paddingLeft:'10px',backgroundColor:'#FFE8E8',color:'#F81F1F',borderRadius:'1em',fontWeight:'600',display:'flex',alignItems:'center',height:'30px'}}>
                                  <img src={closeddot} /> <span style={{marginLeft:'5px'}}>Closed</span>
                                </span>
                              ) :item.status === 3 ? (
                                <span style={{width:'6em',paddingLeft:'10px',backgroundColor:'#FFF6E8',color:'#F8A31F',borderRadius:'1em',fontWeight:'600',display:'flex',alignItems:'center',height:'30px'}}>
                                  <img src={pendingdot} /> <span style={{marginLeft:'5px'}}>Overdue</span>
                                </span>
                              ) :
                              item.status === 4 ? (
                                <span style={{width:'7em',paddingLeft:'10px',backgroundColor:'#898989',color:'white',borderRadius:'1em',fontWeight:'600',display:'flex',alignItems:'center',height:'30px',whiteSpace:'nowrap'}}>
                                  <img src={tododot} /> <span style={{marginLeft:'5px'}}>Written Off</span>
                                </span>
                              ):item.status === 6 ? (
                                <span style={{width:'6em',paddingLeft:'10px',backgroundColor:'#EFE4FF',color:'#6E24E7',borderRadius:'1em',fontWeight:'600',display:'flex',alignItems:'center',height:'30px',whiteSpace:'nowrap'}}>
                                  <img src={initiateddot} /> <span style={{marginLeft:'5px'}}>Refund</span>
                                </span>
                              ):item.status === 5 ? (
                                <span style={{width:'8em',paddingLeft:'10px',backgroundColor:'#EDC7E2',color:'black',borderRadius:'1em',fontWeight:'600',display:'flex',alignItems:'center',height:'30px',whiteSpace:'nowrap'}}>
                                  <img src={initiateddot} /> <span style={{marginLeft:'5px'}}>Mand. Active</span>
                                </span>
                              ):(
                                item.status
                              )
                            ) : (
                              "NA"
                            )}
                            </span></td>
                      </tr>
                     
                      </tbody>
                        )})}
                       

                  </table>
          
              
                </div>
            ):''}
                {filterCustomerName || legacyloanId? null : <>
                  {totalFetchRows ? ( totalFetchRows <= 10 ?(
               <div style={{ textAlign: 'center', fontWeight: 'bold',fontFamily:"Inter", fontSize: '0.9vw',marginLeft:'10px',marginBottom:'5px' }}>
               Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length}{' '}
               records of {totalFetchRows}
             </div>
            ):( <div style={{ textAlign: 'center', fontWeight: 'bold',fontFamily:"Inter", fontSize: '0.9vw',marginLeft:'10px',marginBottom:'5px' }}>
            Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length}{' '}
            records of {totalFetchRows}
          </div>)
             
            ) : (
              <div style={{ textAlign: 'center', fontWeight: 'bold', fontFamily:"Inter", fontSize: '0.9vw' }}>No Records</div>
            )}
                </>}
                
            {filterCustomerName || legacyloanId ? null : <PaginationTable
              startPage="1"
              rowsPerPage={rowsPerPage}
              totalRows={totalFetchRows}
              paginate={paginate}
              isActive={isActive}
              searchText={searchText}
            /> }
           
              </>
            )}
           
              
                </div>
            </div>
        </div>
    )
}
export default LoanTable;