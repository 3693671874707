import React, { useState,useEffect } from 'react';
import axios from 'axios';
import action3 from "../../../assets/images/action3.png"
import Datetime from 'react-datetime';
import jwt_decode from 'jwt-decode';
import {
  Alert,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Spinner,
  FormGroup,
  Input,
} from 'reactstrap';
import dropdown from "../../../assets/images/dropdown.png"
import close from "../../../assets/images/close.png"
//import { useUserAuth } from '../../views/auth/AuthContext.js';
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'

import {userActions} from '../../../actions/user.actions';

const ApplicationStatusEnum = {
  InSubmission: 'In Submission',
  InReview: 'In Review',
  InSanction: 'In Sanction',
  InDisbursement: 'In Disbursement',
  PendingDisbursement: "Pending Disbursement",
  Disbursed: 'Disbursed',
  Rejected: 'Rejected',
  Dropped: 'Dropped',
  OnHold:'On Hold', ReadyToDisburse:'Ready to Disburse',
};
export default function MovePendingStatus(ApplicantId) {

  const droppedstate = useSelector((state) => state.Droppedstatuschange?.res)
  const disbursedstate = useSelector((state) => state.Disbursedstatuschange?.res)
  const dispatch = useDispatch();

 
  const user = sessionStorage.getItem("user")
  const decode=jwt_decode(user)
  const id = ApplicantId.ApplicantId.applicationId


const name = ApplicantId.ApplicantId.name


  const [formLoader, setFormLoader] = useState(false);
  const [formStatusOpen, setStatusOpen] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [disbursedDate, setDisbursedDate] = useState(null);

  const [adjustmentAmt, setAdjustmentAmt] = useState(null);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('Hold On...');
  const [disbursedUTR, setDisbursedUTR] = useState('');
  const [descriptionText, setDescriptionText] = useState('');
  const [apploanId, setLoanId] = useState('');
  const [status, setStatus] = useState('Select Status');


  // const updateDroppedStatusUrl = `${process.env.REACT_APP_BASE_URL}/admin/application/update-status`;
  // const updateInReviewStatusUrl = `${process.env.REACT_APP_BASE_URL}/end-user/submit/admin`;
  
  // const updateDisbursedUrl = `${process.env.REACT_APP_BASE_URL}/admin/application/disburse`;
  // const popUpStatusForm = () => {
  //   setStatusOpen(true);
  // };

  // const handleStatusChange = () => {
  //   if (status === 'Status') {
  //     alert('NO STATUS CHANGE !');
  //   } else if (status === 'Disbursed') {
  //     setFormLoader(!formLoader);
  //     setStatusOpen(!formStatusOpen);
  //     setConfirmationDialog(!confirmationDialog);
  //     const statusData = {
  //       applicationId: ApplicantId.ApplicantId.applicationId,
  //       loanId:apploanId,
  //       userId: ApplicantId.ApplicantId.userId,
  //       status: ApplicationStatusEnum.Disbursed,
  //       adjustmentAmount:adjustmentAmt,
  //       disbursementAmount: ApplicantId.ApplicantId.amount-adjustmentAmt,
  //       disbursementDate: disbursedDate,
  //       utr: disbursedUTR,
  //       notes: descriptionText,
  //     };

  //     axios
  //       .post(`${updateDisbursedUrl}`, statusData, {
  //         headers: {
  //           Authorization: `Bearer ${user}`,
  //           'Content-type': 'application/json',
  //         },
  //       })
  //       .then(
  //         (res) => {
  //           if (res.data.message === 'Successful') {
  //             setConfirmationMessage('Disbursed Successfully');
  //             setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
  //             setTimeout(() => setConfirmationDialog(false), 2000);
  //             setTimeout(() => window.location.reload(true), 500);
  //           }
  //         },
  //         (error) => {
  //          setConfirmationMessage(`Unauthorized --- Error Code ${error}`);
  //            setTimeout(() => setConfirmationDialog(!confirmationDialog), 3500);
  //             setTimeout(() => setConfirmationDialog(false), 3750);
  //             setTimeout(() => window.location.reload(true), 3000);
  //         },
  //       );
  //   }else if (status === 'In Disbursement') {
  //     setFormLoader(!formLoader);
  //     setStatusOpen(!formStatusOpen);
  //     setConfirmationDialog(!confirmationDialog);
  //     const statusData = {
  //       applicationId: ApplicantId.ApplicantId.applicationId,
  //       userId: ApplicantId.ApplicantId.userId,
  //       status: ApplicationStatusEnum.ReadyToDisburse,
  //       notes: descriptionText,
  //     };

  //     axios
  //       .post(`${updateDroppedStatusUrl}`, statusData, {
  //         headers: {
  //           Authorization: `Bearer ${user}`,
  //           'Content-type': 'application/json',
  //         },
  //       })
  //       .then(
  //         (res) => {
  //           if (res.data.message === 'Successful') {
  //             setConfirmationMessage('Moved to In Disbursement Successfully');
  //             setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
  //             setTimeout(() => setConfirmationDialog(false), 2000);
  //             setTimeout(() => window.location.reload(true), 500);
  //           }
  //         },
  //         (error) => {
  //          setConfirmationMessage(`Unauthorized --- Error Code ${error}`);
  //            setTimeout(() => setConfirmationDialog(!confirmationDialog), 3500);
  //             setTimeout(() => setConfirmationDialog(false), 3750);
  //             setTimeout(() => window.location.reload(true), 3000);
  //         },
  //       );
  //   } else if (status === 'Dropped') {
  //     setFormLoader(!formLoader);
  //     setStatusOpen(!formStatusOpen);
  //     setConfirmationDialog(!confirmationDialog);
  //     const statusData = {
  //       userId: ApplicantId.ApplicantId.userId,
  //       applicationId: ApplicantId.ApplicantId.applicationId,
  //       status: ApplicationStatusEnum.Dropped,
  //       notes: descriptionText,
  //     };

  //     axios
  //       .post(`${updateDroppedStatusUrl}`, statusData, {
  //         headers: {
  //           Authorization: `Bearer ${user}`,
  //           'Content-type': 'application/json',
  //         },
  //       })
  //       .then(
  //         (res) => {
  //           if (res.data.message === 'Successful') {
  //             setConfirmationMessage('Dropped Successfully');
  //             setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
  //             setTimeout(() => setConfirmationDialog(false), 2000);
  //             setTimeout(() => window.location.reload(true), 2000);
  //           }
  //         },
  //         (error) => {
  //          setConfirmationMessage(`${error}`);
  //            setTimeout(() => setConfirmationDialog(!confirmationDialog), 2500);
  //             setTimeout(() => setConfirmationDialog(false), 2750);
  //         },
  //       );
  //   }  else if (status === 'On Hold') {
  //     setFormLoader(!formLoader);
  //     setStatusOpen(!formStatusOpen);
  //     setConfirmationDialog(!confirmationDialog);
  //     const statusData = {
  //       userId: ApplicantId.ApplicantId.userId,
  //       applicationId: ApplicantId.ApplicantId.applicationId,
  //       status: ApplicationStatusEnum.OnHold,
  //       notes: descriptionText,
  //     };

  //     axios
  //       .post(`${updateDroppedStatusUrl}`, statusData, {
  //         headers: {
  //           Authorization: `Bearer ${user}`,
  //           'Content-type': 'application/json',
  //         },
  //       })
  //       .then(
  //         (res) => {
  //           if (res.data.message === 'Successful') {
  //             setConfirmationMessage('Application moved to On Hold Status');
  //             setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
  //             setTimeout(() => setConfirmationDialog(false), 2000);
  //             setTimeout(() => window.location.reload(true), 2000);
  //           }
  //         },
  //         (error) => {
  //           setConfirmationMessage(`${error}`);
  //            setTimeout(() => setConfirmationDialog(!confirmationDialog), 2500);
  //             setTimeout(() => setConfirmationDialog(false), 2750);
  //         },
  //       );
  //   } 
  // };

  const updateDroppedStatusUrl = `/admin/application/update-status`;
  const updateInReviewStatusUrl = `/end-user/submit/admin`;
  
  const updateDisbursedUrl = `/admin/application/disburse`;
  const popUpStatusForm = () => {
    setStatusOpen(true);
  };

  const handleStatusChange = () => {
    if (status === 'Status') {
      alert('NO STATUS CHANGE !');
    } else if (status === 'Disbursed') {
      
      const dusbursedData = {
        applicationId: ApplicantId.ApplicantId.applicationId,
        loanId:apploanId,
        userId: ApplicantId.ApplicantId.userId,
        status: ApplicationStatusEnum.Disbursed,
        adjustmentAmount:adjustmentAmt,
        disbursementAmount: ApplicantId.ApplicantId.amount-adjustmentAmt,
        disbursementDate: disbursedDate,
        utr: disbursedUTR,
        notes: descriptionText,
      };

      if(disbursedstate){
        if (disbursedstate.message === 'Successful') {
          setFormLoader(!formLoader);
          setStatusOpen(!formStatusOpen);
          setConfirmationDialog(!confirmationDialog);
          setConfirmationMessage('Disbursed Successfully');
          setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
          setTimeout(() => setConfirmationDialog(false), 2000);
          setTimeout(() => window.location.reload(true), 500);
        }else{
          setConfirmationMessage("Unauthorized --- Error Code ");
             setTimeout(() => setConfirmationDialog(!confirmationDialog), 3500);
              setTimeout(() => setConfirmationDialog(false), 3750);
              setTimeout(() => window.location.reload(true), 3000);
        }
      }else{
        dispatch(userActions.fetchDisbursedstatuschange(updateDisbursedUrl,dusbursedData, user),[])
      }
      
    }else if (status === 'In Disbursement') {
      
      const droppedData = {
        applicationId: ApplicantId.ApplicantId.applicationId,
        userId: ApplicantId.ApplicantId.userId,
        status: ApplicationStatusEnum.ReadyToDisburse,
        notes: descriptionText,
      };

      
      if(droppedstate){
        if (droppedstate.message === 'Successful') {
          setFormLoader(!formLoader);
          setStatusOpen(!formStatusOpen);
          setConfirmationDialog(!confirmationDialog);
          setConfirmationMessage('Moved to In Disbursement Successfully');
          setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
          setTimeout(() => setConfirmationDialog(false), 2000);
          setTimeout(() => window.location.reload(true), 500);
        }else{
          setConfirmationMessage("Unauthorized --- Error Code");
             setTimeout(() => setConfirmationDialog(!confirmationDialog), 3500);
              setTimeout(() => setConfirmationDialog(false), 3750);
              setTimeout(() => window.location.reload(true), 3000);
        }
      }else{
        dispatch(userActions.fetchDroppedstatuschange(updateDroppedStatusUrl,droppedData, user),[])
      }
      
    } else if (status === 'Dropped') {
      
      const statusData = {
        userId: ApplicantId.ApplicantId.userId,
        applicationId: ApplicantId.ApplicantId.applicationId,
        status: ApplicationStatusEnum.Dropped,
        notes: descriptionText,
      };

     
      if(droppedstate){
        if (droppedstate.message === 'Successful') {
          setFormLoader(!formLoader);
          setStatusOpen(!formStatusOpen);
          setConfirmationDialog(!confirmationDialog);
          setConfirmationMessage('Dropped Successfully');
          setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
          setTimeout(() => setConfirmationDialog(false), 2000);
          setTimeout(() => window.location.reload(true), 2000);
        }else{
          setConfirmationMessage("error");
          setTimeout(() => setConfirmationDialog(!confirmationDialog), 2500);
           setTimeout(() => setConfirmationDialog(false), 2750);
        }
      }else{
        dispatch(userActions.fetchDroppedstatuschange(updateDroppedStatusUrl,statusData, user),[])
      }
       
    }  else if (status === 'On Hold') {
     
      const onHoldData = {
        userId: ApplicantId.ApplicantId.userId,
        applicationId: ApplicantId.ApplicantId.applicationId,
        status: ApplicationStatusEnum.OnHold,
        notes: descriptionText,
      };

      if(droppedstate){
        if (droppedstate.message === 'Successful') {
          setFormLoader(!formLoader);
          setStatusOpen(!formStatusOpen);
          setConfirmationDialog(!confirmationDialog);
          setConfirmationMessage('Application moved to On Hold Status');
          setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
          setTimeout(() => setConfirmationDialog(false), 2000);
          setTimeout(() => window.location.reload(true), 2000);
        }else{
          setConfirmationMessage("error");
          setTimeout(() => setConfirmationDialog(!confirmationDialog), 2500);
           setTimeout(() => setConfirmationDialog(false), 2750);
        }
      }else{
        dispatch(userActions.fetchDroppedstatuschange(updateDroppedStatusUrl,onHoldData, user),[])
      }
      
    } 
  };

  useEffect(()=>{
    handleStatusChange();
  },[droppedstate,disbursedstate])

  const handleToggle = () => {
    setDropDownOpen(!dropDownOpen);
  };
  const handleDescriptionText = (text) => {
    setDescriptionText(text);
  };

  return (
    <div>
            <img  onClick={() => popUpStatusForm()} style={{cursor:'pointer',width:'18px'}} src={action3} alt="resend consent"/>

      <Modal  isOpen={confirmationDialog}>
        <ModalHeader>Waiting For Confirmation</ModalHeader>
        <ModalBody>{confirmationMessage}</ModalBody>
      </Modal>
      {formStatusOpen && (
        <>
        <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 999,
        }}
      ></div>
      <Modal
       style={{
        
        width:"600px",
        
        top:"10%",
       
        
        borderRadius:"10px",
      }} isOpen={formStatusOpen}>
         <div style={{backgroundColor:"#E9E9E9",borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}}>
            <div style={{display:"flex",flexDirection:"row",justifyContent:'space-between',height:'60px',padding:"10px"}}>
                <p style={{fontFamily:"Inter-Medium",fontSize:'14px'}}>Update Status Here ( Pending Approvals to <span style={{fontFamily:"Inter-Medium",color:'#D32028'}}>{status}</span> )</p>
                <p style={{fontFamily:"Inter-Medium",cursor: 'pointer'}} onClick={() => setStatusOpen(!formStatusOpen)}>
                  <img src={close}/>
                </p>
            </div>
            </div>
           
            <div style={{marginTop:'10px'}}>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",backgroundColor:'#FCF0F1',width:"100%",borderRadius:"10px"}}>
              <p style={{marginLeft:"15px",fontFamily:"Inter-Medium",fontSize:"14px",color:"#D32028"}}>Application ID <br/><span style={{fontFamily:"Inter-Medium",fontSize:"14px",color:"black"}}>{id}</span></p>
               <p style={{marginRight:"15px",fontFamily:"Inter-Medium",fontSize:"14px",color:"#D32028"}}> Applicant Name<br/><span style={{fontFamily:"Inter-Medium",fontSize:"14px",color:"black"}}>{name}</span></p>
            </div>
         
          
        </div>
        <ModalBody>
          <Form>
            <FormGroup>
             {ApplicantId.ApplicantId.fundSourceCode==="GBP"?<p style={{border:'1px solid orange',padding:'1em',borderRadius:'10px',backgroundColor:'#fff0f5'}}><strong>Disburse</strong> from this form is disabled for fund source : GBP (Glaze Barter PVT LTD). <span style={{textDecoration:'underline'}}>Proceed with checkbox and disburse selected</span></p>:null}
             <p style={{lineHeight:'10px',fontFamily:"Inter-Medium",fontSize:'14px'}}>Select Status*</p>
              <Dropdown isOpen={dropDownOpen} toggle={() => handleToggle()}>
              <DropdownToggle style={{lineHeight:'10px',textAlign:"left",height:'40px',width:'460px',backgroundColor:'white',color:'gray',fontFamily:"Inter-Medium",fontSize:'14px'}} >
                  {status}<span style={{ position: 'absolute', left: '430px', top: '50%', transform: 'translateY(-50%)' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                      <path fill="currentColor" d="M1.5 4.5l4.5 4.5 4.5-4.5z" />
                    </svg>
                  </span>
                </DropdownToggle>
                <DropdownMenu >
                  <DropdownItem style={{fontFamily:"Inter-Medium",fontSize:'14px'}} onClick={(e) => setStatus(e.target.innerText)} disabled={decode.role==='loan_processor'||decode.role==="user"||ApplicantId.ApplicantId.fundSourceCode==="GBP"?true:false} >
                    Disbursed
                  </DropdownItem>
               
                  <DropdownItem style={{fontFamily:"Inter-Medium",fontSize:'14px'}} onClick={(e) => setStatus(e.target.innerText)} >
                    In Disbursement
                  </DropdownItem>
                  <DropdownItem style={{fontFamily:"Inter-Medium",fontSize:'14px'}} onClick={(e) => setStatus(e.target.innerText)} >
                    Dropped
                  </DropdownItem>
                  <DropdownItem style={{fontFamily:"Inter-Medium",fontSize:'14px'}}  onClick={(e) => setStatus(e.target.innerText)}>
                    On Hold
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              {status === 'Disbursed' ? (
                <div>
                
                <p style={{marginTop:"20px",lineHeight:'1px',fontFamily:"Inter-Medium",fontSize:'14px'}}>Remarks</p>
              <input
                type="text"
                id="description"
                placeholder="Write Description Here"
                style={{borderRadius:"5px",borderStyle:"solid",borderWidth:"1px", height: '40px', width: '460px',fontFamily:"Inter-Medium",fontSize:'14px' }}
                onChange={(e) => handleDescriptionText(e.target.value)}
              />
              </div>
              ) : (
                <></>
              )}
              {status === 'In Disbursement' ? (
                <div>
                
                <p style={{marginTop:"20px",lineHeight:'1px',fontFamily:"Inter-Medium",fontSize:'14px'}}>Remarks</p>
              <input
                type="text"
                id="description"
                placeholder="Write Description Here"
                style={{borderRadius:"5px",borderStyle:"solid",borderWidth:"1px", height: '40px', width: '460px',fontFamily:"Inter-Medium",fontSize:'14px' }}
                onChange={(e) => handleDescriptionText(e.target.value)}
              />
              </div>
              ) : (
                <></>
              )}
              {status === 'Dropped' ? (
                <div>
                <p style={{marginTop:"20px",lineHeight:'1px',fontFamily:"Inter-Medium",fontSize:'14px'}}>Remarks</p>
              <input
                type="text"
                id="description"
                placeholder="Write Description Here"
                style={{borderRadius:"5px",borderStyle:"solid",borderWidth:"1px", height: '40px', width: '460px',fontFamily:"Inter-Medium",fontSize:'14px' }}
                onChange={(e) => handleDescriptionText(e.target.value)}
              />
              </div>
              ) : (
                <></>
              )}
              {status === 'On Hold' ? (
                <div>
                
                <p style={{marginTop:"20px",lineHeight:'1px',fontFamily:"Inter-Medium",fontSize:'14px'}}>Remarks</p>
              <input
                type="text"
                id="description"
                placeholder="Write Description Here"
                style={{borderRadius:"5px",borderStyle:"solid",borderWidth:"1px", height: '40px', width: '460px',fontFamily:"Inter-Medium",fontSize:'14px' }}
                onChange={(e) => handleDescriptionText(e.target.value)}
              />
              </div>
              ) : (
                <></>
              )}
             
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter style={{display:"flex",flexDirection:"row",justifyContent:"center",backgroundColor:"#E9E9E9",height:'60px'}}>

         
          <Button style={{backgroundColor:"#D32028",color:"white",fontFamily:"Inter-Medium",fontSize:"13px",border:"solid",borderRadius:"5px",borderWidth:"1px",height:"30px"}} disabled={formLoader} onClick={() => handleStatusChange()}>
            Make Changes
          </Button>
        </ModalFooter>
      </Modal>
      </>
      )}
    </div>
  );
}


