import React,{useState,useEffect} from "react";
import axios from 'axios';
import { AppBar, List, ListItem ,Grid ,Box , Hidden,} from "@mui/material";
import {  Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap'
import Toolbar from "@mui/material/Toolbar";
import jwt_decode from 'jwt-decode';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useNavigate, useLocation} from 'react-router-dom'
// import {useUserAuth} from '../../AuthContext';
import { Homestyle } from "./Style";
import Dropdownnav from "./Dropdownnav";
import Notifications from './Notifications'
import Mobiledraw from './Mobiledraw'
// import axiosInstance from "../../helpers/axios";
  // import { useNavigate } from "react-router-dom";


export default function Navbar() {
 
 
 
 const user=sessionStorage.getItem('user');
  const navigate=useNavigate();
  const location=useLocation()
  
  var decode={}
  if(user)
  decode=jwt_decode(user); 
  
//   const [branches,setBranches]=useState(null)
// const [currBranch,setCurrBranch]=useState(sessionStorage.getItem("bname"))

//   const navigate=useNavigate();
//   const location=useLocation();
//   const userId=decode?.userId

//   const urlForAllBranch=`/branch/fetch/all/${userId}`

//   useEffect(() => {

//     if(sessionStorage.getItem("bname")==="null"||sessionStorage.getItem("bname")===null)
//     {
//       sessionStorage.setItem("bname","Cumulative of all Branches");
//       sessionStorage.setItem("bid",null);

//     }
    
//     setTimeout(() => {
      
//       axiosInstance.get(urlForAllBranch)
//       .then((res)=>{
//         setCurrBranch(sessionStorage.getItem("bname"))
//         setBranches(res.data.res)
  
//       })
//     }, 500);

  
   
//   }, [urlForAllBranch])
  
const [dropDownOpen,setDropDownOpen]=useState(false)

// const handleSetBranch=(e,item)=>{
//     const currentPage=window.location.pathname

//   if(currentPage==="/feeCycles/description"||currentPage==="/studentList/details")
//   {
//     navigate(-1)
//     alert("change branch first to see description")
//   }
//   else{

//     setCurrBranch(e.target.innerText)
//     sessionStorage.setItem("bid",item._id);
//     sessionStorage.setItem("bname",item.branchName);
//     window.location.reload()
//   }

// }
// const handleSetBranchDefault=(e)=>{
//   const currentPage=window.location.pathname

//   if(currentPage==="/feeCycles/description")
//   {
//     navigate(-1)
//     alert("change branch first to see description")

//   }
//   else
//   {
    
//     setCurrBranch(e.target.innerText)
//   sessionStorage.setItem("bid",null);
//   sessionStorage.setItem("bname","Cumulative of All Branches");

//   window.location.reload()
//   }

// }



const goBack=(e)=>{
  navigate(-1)
  e.preventDefault()
}
  return (
    <Homestyle id="NavBar">
      <AppBar position="static" color="primary" className="appbar">
        <Toolbar>
          <Grid container >
            <Grid item xs={3} >
               {/* { location.pathname==="/"||location.pathname==="/feeCycles"||location.pathname==="/studentList"?null: */}
  <>
  {location?.pathname!=="/dashboard"?          
  <Button onClick={(e) => goBack(e)} color="primary" variant="outlined" size="small">

<List>
  
  <ListItem>
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5333 6.59058H0.907349M0.907349 6.59058L5.79431 11.4775M0.907349 6.59058L5.79431 1.70361"
        stroke="#101828"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </ListItem>
  <ListItem className="backlft" >Back</ListItem>
</List>
</Button>
:null}
</>
{/* } */}
            </Grid>

            <Grid item xs={9} >
              <Box textAlign="end" >
              <List>
               <ListItem>
                 {decode?.role!=="institute_user"?<div style={{marginRight:'4em'}}><Notifications/></div>:null }
                 {/* 
                <div style={{width:'8em',marginRight:'10em'}}>
                     <Dropdown  isOpen={dropDownOpen} toggle={()=>setDropDownOpen(!dropDownOpen)}>
                      <DropdownToggle style={{width:'15em',background:'#fff', color : '#000' ,border:'none'}} caret  >
                        {currBranch}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem  onClick={(e)=>handleSetBranchDefault(e,null)}> 
                         Cumulative of all Branches
                        </DropdownItem>
                      
                      {  
                        branches?branches.map((item)=>{
                        return(
                        <DropdownItem  onClick={(e)=>handleSetBranch(e,item)}> 
                          {item.branchName}
                        </DropdownItem>
                        )
                        }
                      ):null
                      }
                     
                      </DropdownMenu>
                    </Dropdown>
                    
                </div>
                */}
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="20" fill="#D9D9D9"/>
<path d="M28.4141 27.4386V25.3886C28.4141 24.3013 27.9822 23.2585 27.2133 22.4896C26.4445 21.7207 25.4016 21.2888 24.3143 21.2888H16.1146C15.0273 21.2888 13.9845 21.7207 13.2156 22.4896C12.4467 23.2585 12.0148 24.3013 12.0148 25.3886V27.4386M24.3143 13.0891C24.3143 15.3534 22.4787 17.1889 20.2145 17.1889C17.9502 17.1889 16.1146 15.3534 16.1146 13.0891C16.1146 10.8248 17.9502 8.98926 20.2145 8.98926C22.4787 8.98926 24.3143 10.8248 24.3143 13.0891Z" stroke="#101828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


                </ListItem> 
                <ListItem  sx={{ marginLeft:"10px"}} >
                  {/* inner list */}
              <div className="sarath" >
              <Typography variant="body2" component="div" >
            {decode.display_name}
          </Typography>
          <Typography variant="body2" component="div" fontFamily="Inter-Light"  color="secondary.light" >
            {decode.role!=="institute_user"&&decode.role!=="institute_admin"?decode.mobile:""}
          </Typography>
              </div>

        
          {/* inner list ended */}
                </ListItem>
                <ListItem>
                  {/* drop down started */}
                  <Dropdownnav/>
                  {/* drop down eded */}
                </ListItem>
                <Hidden lgUp >
                <ListItem>
                  {/* drop down started */}
                  <Mobiledraw/>
                  {/* drop down eded */}
                </ListItem>
                </Hidden>
              </List>
              </Box>
            </Grid>    
          </Grid>
    
     
        
        </Toolbar>
      </AppBar>
    </Homestyle>
  );
}
