 
import React,{useState} from 'react';
import axiosInstance from '../../../helpers/axios'
import jwt_decode from 'jwt-decode'
import {
  
  Modal,
  ModalBody,
  Button,
  ModalHeader,
} from 'reactstrap';
import { TransformWrapper,TransformComponent} from 'react-zoom-pan-pinch';

export default function ViewFile({currUserId,currApplicationId,type,item,image , item2,profileDetails}) {

  const user=sessionStorage.getItem('user')
  const decode=jwt_decode(user)
  const [imageOpen, setImageOpen] = useState(false);
  const [itemPath,setitemPath] = useState(null)
  const [item2Path,setitem2Path] = useState(null)
 
const handleClick = ()=>{
  const url = `/file/url-content?path=${item}`
  axiosInstance
      .get(url, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res)=>{
        setitemPath(res?.data?.data)
        })
        if(item2){
          const url1 = `/file/url-content?path=${item2}`
          axiosInstance
              .get(url1, {
                headers: {
                  Authorization: `Bearer ${user}`,
                },
              })
              .then((res)=>{
                setitem2Path(res?.data?.data)
                })
        }
       
}

// const createBlobURL = (base64Data, mimeType) => {
//   const byteCharacters = atob(base64Data);
//   const byteNumbers = new Array(byteCharacters.length);
//   for (let i = 0; i < byteCharacters.length; i++) {
//       byteNumbers[i] = byteCharacters.charCodeAt(i);
//   }
//   const byteArray = new Uint8Array(byteNumbers);
//   const blob = new Blob([byteArray], {type: mimeType});
//   return URL.createObjectURL(blob);
// };


// const itemBlobURL = itemPath ? createBlobURL(itemPath, 'application/pdf') : createBlobURL(itemPath, 'application/pdf');
console.log(item2Path)
const [sanctionModal, setModalSanction] = useState(false);
const toggleSanction = () => setModalSanction(!sanctionModal);
  return (
    <>
     <Modal isOpen={sanctionModal}  size="xl" style={{height:'100%'}}>
        <ModalHeader toggle={toggleSanction}></ModalHeader>
        <ModalBody >
        <iframe
      src={
        itemPath?
          itemPath
          : item2Path
      }
      width="100%"
      height="1000vh"
      style={{ border: 'none' }}
    ></iframe>
        </ModalBody>
       
       
      </Modal>
    <Modal style={{overflow:'auto',height:'40em'}} size="xl" isOpen={imageOpen}>
        <ModalHeader >Uploaded File <i>( Use Scroll to Zoom in the Picture )</i> <Button style={{position: 'absolute',right:'2em'}} type="button" onClick={()=>setImageOpen(!imageOpen)}>X</Button></ModalHeader>
        <ModalBody  style={{margin:'5%',border:'2px dashed #D0D0D0',display:'block',justifyContent:'center'}} >

        {item?.substring(item?.lastIndexOf(".")+1)==="png"||item?.substring(item?.lastIndexOf(".")+1)==="jpeg"||item?.substring(item?.lastIndexOf(".")+1)==="jpg"?
         <TransformWrapper
            defaultScale={1}
           >
            <TransformComponent>
               <img style={{width:'100%'}} src={itemPath} alt="File"/>
               {item2?<img src={item2Path} style={{width:'100%'}} alt="file"/>:null}
            </TransformComponent>
        </TransformWrapper>:
            <a target="_blank" rel="noreferrer" onClick={toggleSanction} style={{color:'blue',cursor:'pointer',textDecoration:'underline'}}>
            Click To Download
            </a>
          } 
     
        
      
          </ModalBody>
      </Modal>
      <img src={image} onClick={()=>{setImageOpen(!imageOpen);handleClick()}}/>
      </>
  )
}
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 