import React,{useEffect, useState} from 'react';
import {
  
  Modal,
  ModalBody,
  Button,
  ModalHeader,
} from 'reactstrap';
import successful from '../../../assets/images/successful.png'
import attention from '../../../assets/images/attention.png'
import faceMatch from '../../../assets/images/faceMatch.png'
import panImage from '../../../assets/images/panVerification.png'
import faceLive from '../../../assets/images/facelive.png'
import openMap from '../../../assets/images/openMap.png'
import ckyckImage from '../../../assets/images/ckyckImage.png'
import digilocker from '../../../assets/images/digilocker.png'
import documentImage from '../../../assets/images/documentSample.png'
import viewBig from '../../../assets/images/viewBig.png'
import frame from '../../../assets/images/Frame.png'
import bankImg from '../../../assets/images/bankImg.png'
import ViewFile from '../Files/viewFile.js'
import axiosInstance from '../../../helpers/axios.js';
import {useSelector,useDispatch} from 'react-redux'
import { userActions } from '../../../actions/user.actions.js';
import './index.css'

export default function KycProfile({kycDetails,bankVerificationDetails,digilockerDetails,panDetails,profileDetails,appId}) {
  const user = sessionStorage.getItem("user")
  const panProState=useSelector((state)=>state?.PANPRO?.panStatus?.res);
  const panState=useSelector((state)=>state?.PAN?.panStatus?.res);

  console.log("digilockerDetails",digilockerDetails)

  const status = sessionStorage.getItem("status")
  const [imageOpen, setImageOpen] = useState(false);
  const [panProDetails,setPanProDetails] = useState(null)
  const dispatch = useDispatch();


   const DocumentTypeArray = ["", "Proof of Identity", "Proof of Address"]

 const DocumentArray = ["", "Aadhaar", "Pan"]

 const VerificationMethodArray = [
  "",
  "Digio OCR",
  "Digi locker",
  "Zoop Service",
  "TransUnion",
  "pan pro",
]
const panUrl=`/application/pan-details?userId=${profileDetails?.userId}&applicationId=${appId}`;

useEffect(()=>{
  console.log(panProDetails,"PAN PRO")
  if(panProDetails?.status === "valid"){
    dispatch(userActions.getPanStatus(panUrl));

  }
},[panProDetails,panDetails])
const triggerPanPro=()=>{
  
  const panProUrl = `/pan-pro/verify`

  const data = {
    panNumber:profileDetails?.panId,
    userId:profileDetails?.userId,
    applicationId:appId,
    dateOfBirth:profileDetails?.dateOfBirth,
  }
   axiosInstance.post(panProUrl,data).then((res) => {
                      
          console.log(res?.data?.data,"KYC")
          setPanProDetails(res?.data?.data)
           }
   )
     .catch((err)=>{return err})
}
console.log("pan details",panDetails)
const [itemPath,setitemPath] = useState(null)
const handleClick = (item)=>{
  const url = `/file/url-content?path=${item}`
  axiosInstance
      .get(url, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res)=>{
        setitemPath(res?.data?.data)
        if(res?.data?.data){
          window.open(res?.data?.data, '_blank');
        }
        })
        .catch((error)=>console.log(error))
}
  return (
    <div style={{display:'block'}}>
      
        <div style={{display:'flex',justifyContent: 'space-between',marginRight:'2em',marginLeft:'1em'}}>

            <div style={{borderRadius:'1em',width:'50%',margin:'1em',boxShadow:'0px 0px 2px 2px  #f0f0f0'}}>
              <div style={{display:'flex',justifyContent:'space-around',}}>
                <div style={{display:'flex',justifyContent: 'space-between',padding:'1em',width:'100%',borderBottom:'1px solid #f0f0f0'}}>
                <p style={{fontSize:'18px',fontWeight:'bold',fontFamily:'Outfit'}}><img style={{marginRight:'0.5em'}} src={bankImg}/>Bank Verification</p>
                <p>{bankVerificationDetails?.bankDetails?.verificationStatus==="VERIFIED"?<img src={successful}/>:<img src={attention}/>}</p>
                </div>

              </div>
               <div style={{padding:'1em',paddingLeft:'2em',marginTop:'2em'}}>
                <div style={{ }}>
                <p style={{color:'#667085',}}>Beneficiary Name</p>
                <p style={{fontWeight:'bold'}}>{bankVerificationDetails?.bankDetails?.beneficiaryName}</p>
                </div>

                <div style={{display:'flex',justifyContent:'space-between'}}>
                  <div style={{display:'block'}}>
                  <p style={{color:'#667085'}}>Account Number</p>
                  <p style={{fontWeight:'bold'}}>{bankVerificationDetails?.bankDetails?.accountNumber}</p>
                  </div>
                  <div style={{display:'block'}}>
                  <p style={{color:'#667085'}}>IFSC Code</p>
                  <p style={{fontWeight:'bold'}}>{bankVerificationDetails?.bankDetails?.ifsc}</p>
                  </div>
                  
                </div>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                  <div style={{display:'block'}}>
                  <p style={{color:'#667085'}}>Name Match Score</p>
                  <p style={{borderRadius:'1em',width:'3.5em',textAlign:'center',fontWeight:'bold',color:bankVerificationDetails?.bankDetails?.matchPercentage>65?'green':'red',backgroundColor:bankVerificationDetails?.bankDetails?.matchPercentage>65?'#ECFDF3':'#FFF6E8'}}>{Math.round(bankVerificationDetails?.bankDetails?.matchPercentage)} %</p>
                  </div>
                  
                  
                </div>
                </div>

            </div>
            <div style={{borderRadius:'1em',width:'50%',margin:'1em',boxShadow:'0px 0px 2px 2px  #f0f0f0'}}>
              <div style={{display:'flex',justifyContent:'space-around',}}>
                <div style={{display:'flex',justifyContent: 'space-between',padding:'1em',width:'100%',borderBottom:'1px solid #f0f0f0'}}>
                <p style={{fontSize:'18px',fontWeight:'bold',fontFamily:'Outfit'}}><img style={{marginRight:'0.5em'}} src={faceLive}/>Face Match</p>
                <p>{bankVerificationDetails?.selfieDetails?.facematchScore==="100"?<img src={successful}/>:<img src={attention}/>}</p>
                </div>

              </div>
               <div style={{padding:'1em'}}>
               

                <div style={{display:'flex',justifyContent:'space-between',padding:'1em'}}>
                  <div style={{display:'block'}}>
                   <p style={{color:'#667085'}}>Face Match Score</p>
                    <p style={{fontWeight:'bold'}}>{bankVerificationDetails?.selfieDetails?.facematchScore} %</p>
                  </div>
                  <div style={{display:'block'}}>
                  <p style={{color:'#667085'}}>Accuracy</p>
                  <p style={{fontWeight:'bold'}}>{Math.round(bankVerificationDetails?.selfieDetails?.geoTagAccuracy)}</p>
                  </div>
                
                </div>
                <div style={{display:'flex',justifyContent:'space-between',padding:'1em', }}>
                  <div style={{display:'block'}}>
                   <p style={{color:'#667085'}}>latitude</p>
                    <p style={{fontWeight:'bold'}}>{Math.round(bankVerificationDetails?.selfieDetails?.geoTagLatitude*1000000)/1000000}</p>
                  </div>
                  <div style={{display:'block'}}>
                  <p style={{color:'#667085'}}>Longitude</p>
                  <p style={{fontWeight:'bold'}}>{Math.round(bankVerificationDetails?.selfieDetails?.geoTagLongitude*1000000)/1000000}</p>
                  </div>
                
                </div>
                <div style={{display:'flex',justifyContent:'space-between',padding:'1em'}}>
                  <div style={{display:'block'}}>
                   <p style={{color:'#667085'}}>Geo-Address 
                   
                   <a  href={`https://maps.google.com/maps?q=${bankVerificationDetails?.selfieDetails?.geoTagLatitude},${bankVerificationDetails?.selfieDetails?.geoTagLongitude}&hl=es;z=14&amp;output=embed`}
                  style={{ color: 'inherit' ,backgroundColor:'aliceBlue',textAlign:"left",textDecoration:'underline', marginLeft:'1em',fontWeight:'bold' }}
                  target="_blank"
                  rel='noreferrer'>Open Map</a> <img src={openMap}/></p>

                  <p style={{fontWeight:'bold'}}>{bankVerificationDetails?.selfieDetails?.geoTagAddress}</p>
                  </div>
                   
                
                </div>
          </div>

            </div>
        </div>
        <div style={{display:'flex',justifyContent: 'space-between',marginRight:'2em',marginLeft:'1em'}}>

            <div style={{borderRadius:'1em',width:'50%',margin:'1em',boxShadow:'0px 0px 2px 2px  #f0f0f0'}}>
              <div style={{display:'flex',justifyContent:'space-around',}}>
                <div style={{display:'flex',justifyContent: 'space-between',width:'100%',borderBottom:'1px solid #f0f0f0',alignItems:'center',padding:'10px'}}>
                <p style={{fontSize:'18px',fontWeight:'bold',paddingTop:'8px'}}><img style={{marginRight:'0.5em'}} src={panImage}/>PAN Verification</p>
                <p>{panState?.status==="valid"?<img src={successful}/>:<div><img src={attention}/><br/><span style={{color:'#D32028',fontWeight:'bold',fontFamily:'Inter-Medium',marginLeft:'5px'}}>{panState?.status}</span></div>}
                  </p>
              {status === "In Review" ? <>
              {panState?.status!=="valid" || panState?.aadhaarLinkStatus !=="valid" ? <p style={{backgroundColor:'#D32028',color:'white',fontFamily:'Inter-Medium',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'1em',paddingLeft:'10px',paddingRight:'10px',cursor:'pointer'}} onClick={triggerPanPro}>Retrigger Pan</p> : null} 
              </>:null}
                </div>

              </div>
            <div style={{display:'flex',justifyContent: 'space-between'}}>
               <div style={{padding:'1em',paddingLeft:'2em'}}>
                <p style={{color:'#667085'}}>Document Type</p>
                <p style={{fontWeight:'bold'}}>{DocumentTypeArray[panState?.documentType]}</p>
 
                </div>
              
                
               <div style={{padding:'1em',paddingLeft:'2em'}}>
                <p style={{color:'#667085'}}>Verification Method</p>
                <p style={{fontWeight:'bold'}}>{panProDetails ? <>{VerificationMethodArray[panProDetails?.verificationMethod]}</>:<>{VerificationMethodArray[panState?.verificationMethod]}</>}
                  </p>
                </div>
            </div>
            {panProDetails?
              <div style={{display:'flex',justifyContent: 'space-between'}}>
              <div style={{padding:'1em',paddingLeft:'2em'}}>
               <p style={{color:'#667085'}}>Name</p>
               <p style={{fontWeight:'bold'}}>{panProDetails?.name}</p>

               </div>
               
              <div style={{padding:'1em',paddingLeft:'2em',marginRight:'3em'}}>
               <p style={{color:'#667085'}}>Date of birth</p>
               <p style={{fontWeight:'bold'}}>{panProDetails?.dob}
                 </p>
               </div>
           </div>:<div style={{display:'flex',justifyContent: 'space-between'}}>
              <div style={{padding:'1em',paddingLeft:'2em'}}>
               <p style={{color:'#667085'}}>Name</p>
               <p style={{fontWeight:'bold'}}>{panDetails?.name}</p>

               </div>
               
              <div style={{padding:'1em',paddingLeft:'2em',marginRight:'3em'}}>
               <p style={{color:'#667085'}}>Date of birth</p>
               <p style={{fontWeight:'bold'}}>{panDetails?.dob}
                 </p>
               </div>
               
           </div>
            }
            <div style={{display:'flex',justifyContent: 'space-between'}}>
            {panProDetails?(
            <> <div style={{padding:'1em',paddingLeft:'2em'}}>
            <p style={{color:'#667085'}}>Name Match Score</p>
            <p style={{borderRadius:'1em',width:'3.5em',textAlign:'center',fontWeight:'bold',color:panProDetails?.nameMatchScore>65?'green':'red',backgroundColor:panProDetails?.nameMatchScore>65?'#ECFDF3':'#FFF6E8'}}>{Math.round(panProDetails?.nameMatchScore)} %</p>


            </div>
            </>
            ): <div style={{padding:'1em',paddingLeft:'2em'}}>
            <p style={{color:'#667085'}}>Name Match Score</p>
            <p style={{borderRadius:'1em',width:'3.5em',textAlign:'center',fontWeight:'bold',color:panDetails?.nameMatchScore>65?'green':'red',backgroundColor:panDetails?.nameMatchScore>65?'#ECFDF3':'#FFF6E8'}}>{Math.round(panDetails?.nameMatchScore)} %</p>

            </div>}
            <div style={{padding:'1em',paddingLeft:'2em'}}>
                <p style={{color:'#667085'}}>Aadhar Linked Staus</p>
                <p style={{fontWeight:'bold'}}>{panState?.aadhaarLinkStatus}</p>
 
                </div>
        

            </div>
            </div>
            
            <div style={{borderRadius:'1em',width:'50%',margin:'1em',boxShadow:'0px 0px 2px 2px  #f0f0f0'}}>
              <div style={{display:'flex',justifyContent:'space-around',}}>
                <div style={{display:'flex',justifyContent: 'space-between',padding:'1em',width:'100%',borderBottom:'1px solid #f0f0f0'}}>
                <p style={{fontSize:'18px',fontWeight:'bold',fontFamily:'Outfit'}}><img style={{marginRight:'0.5em'}} src={faceMatch}/>OTP Match</p>
                <p>{digilockerDetails?.videoDetails?.otpScore <="70"?<img src={attention}/>:<img src={successful}/>}</p>
                </div>

              </div>
               <div style={{padding:'1em'}}>
               

                <div style={{display:'flex',justifyContent:'space-between',padding:'1em'}}>
                  <div style={{display:'block'}}>
                   <p style={{color:'#667085',}}>OTP Match (%)</p>
                    <p style={{fontWeight:'bold'}}>{digilockerDetails?.videoDetails?.otpScore}</p>
                  </div>
                  <div style={{display:'block'}}>
                   <p style={{color:'#667085',}}>Video</p>
                    <a style={{fontWeight:'bold'}} href={digilockerDetails?.videoDetails?.video} target='_blank' rel="noreferrer">Click to see</a>
                  </div>
                  <div style={{display:'block'}}>
                   <p style={{color:'#667085',}}>OTP</p>
                    <p style={{fontWeight:'bold'}}>{digilockerDetails?.videoDetails?.otp}</p>
                  </div>
               
                </div>
               
          </div>

            </div>
        </div>
          
        
         <div style={{borderRadius:'1em',width:'95%',marginLeft:'2em',boxShadow:'0px 0px 2px 2px  #f0f0f0'}}>
              <div style={{display:'flex',justifyContent:'space-around',}}>
                <div style={{display:'flex',justifyContent: 'space-between',padding:'1em',width:'100%',borderBottom:'1px solid #f0f0f0'}}>
                <p style={{fontSize:'18px',fontWeight:'bold',fontFamily:'Outfit'}}><img style={{marginRight:'0.5em'}} src={frame}/>ID Verification<img style={{marginLeft:'1em'}} src={ckyckImage}/></p>
                <p>{kycDetails?.ckycNo?<img src={successful}/>:<img src={attention}/>}</p>
                </div>

              </div>
               <div style={{padding:'1em'}}>
               

                <div style={{display:'flex',justifyContent:'space-between',padding:'1em'}}>
                  <div style={{display:'block',width:'12em'}}>
                   <p style={{color:'#667085'}}>C-KYC No</p>
                    <p style={{fontWeight:'bold'}}>{kycDetails?.ckycNo} </p>
                  </div>
                  <div style={{display:'block',width:'12em'}}>
                   <p style={{color:'#667085'}}>Last Updated</p>
                    <p style={{fontWeight:'bold'}}>{kycDetails?.kycDate}</p>
                  </div>
                  <div style={{display:'block',width:'12em'}}>
                   <p style={{color:'#667085'}}>DOB</p>
                    <p style={{fontWeight:'bold'}}>{kycDetails?.dateOfBirth} </p>
                  </div>
                  <div style={{display:'block',width:'12em'}}>
                   <p style={{color:'#667085'}}>Gender</p>
                    <p style={{fontWeight:'bold'}}>{kycDetails?.gender} </p>
                  </div>
               
                </div>
                <div style={{display:'flex',justifyContent:'space-between',padding:'1em'}}>
                  <div style={{display:'block',width:'12em'}}>
                   <p style={{color:'#667085'}}>Name</p>
                    <p style={{fontWeight:'bold'}}>{kycDetails?.fullName} </p>
                  </div>
                  <div style={{display:'block',width:'12em'}}>
                   <p style={{color:'#667085'}}>Father/Spouse Name</p>
                    <p style={{fontWeight:'bold'}}>{kycDetails?.fatherName} </p>
                  </div>
                  <div style={{display:'block',width:'12em'}}>
                   <p style={{color:'#667085'}}>Mother Name</p>
                    <p style={{fontWeight:'bold'}}>{kycDetails?.motherName} </p>
                  </div>
                  <div style={{display:'block',width:'12em'}}>
                   <p style={{color:'#667085'}}>Email ID</p>
                    <p style={{fontWeight:'bold'}}>{kycDetails?.email} </p>
                  </div>
               
                </div>
                <div style={{display:'flex',justifyContent:'space-between',padding:'1em'}}>
                  <div style={{display:'block',width:'12em'}}>
                   <p style={{color:'#667085'}}>Permanent Address</p>
                    <p style={{fontWeight:'bold',width:'10em'}}>{kycDetails?.permanentAddress?.length>40?kycDetails?.permanentAddress?.substr(0,50):kycDetails?.permanentAddress}</p>
                  </div>
                  <div style={{display:'block',width:'12em'}}>
                   <p style={{color:'#667085'}}>Correspondence Address</p>
                    <p title={kycDetails?.currentAddress} style={{fontWeight:'bold',width:'10em'}}>{kycDetails?.currentAddress?.length>40?kycDetails?.currentAddress?.substr(0,50):kycDetails?.currentAddress}</p>
                  </div>
                  <div style={{display:'block',width:'12em'}}>
                   <p style={{color:'#667085'}}>Images Obtained</p>
                    {kycDetails?.imageList?.map((item)=>{
                      return(
                           <p style={{fontWeight:'bold'}}><ViewFile item={item?.imageUrl?item?.imageUrl:null} image={viewBig}  /><br/><small>{item?.type?item?.type:null}</small></p>
                      )
                    })}
                   
                  </div>
                 
               
                </div>
                
              

    {digilockerDetails?
    <>
      <div style={{display:'flex',justifyContent:'space-around',}}>
                <div style={{display:'flex',justifyContent: 'space-between',padding:'1em',width:'100%',borderBottom:'1px solid #f0f0f0'}}>
                <p style={{fontSize:'18px',fontWeight:'bold',fontFamily:'Outfit'}}><img style={{marginRight:'0.5em'}} src={frame}/>ID Verification<img style={{marginLeft:'1em'}} src={digilocker}/></p>
             
                </div>

       </div>
     <div >
               

         
                <div style={{marginBottom:'1em',display:'block'}}>
                          <div style={{display:'flex',justifyContent:'space-between', paddingLeft: '1em',
                                paddingRight: '1em',}}>
                              <div style={{display:"block"}}>
                                <p style={{ fontWeight: '700' }}>
                                Aadhaar Number
                                </p>
                                {digilockerDetails?.response?.response?.aadhaar?.id_number}
                              </div>

                              <div style={{display:"block"}}>
                                
                                <p style={{ fontWeight: '700' }}>
                                Name 
                                </p> {digilockerDetails?.response?.response?.aadhaar?.name}
                              </div>
                              <div style={{display:"block"}}>
                                <p style={{ fontWeight: '700' }}>


                              
                                Gender 
                                </p> {digilockerDetails?.response?.response?.aadhaar?.gender}
                              </div>
                              <div style={{display:"block"}}>
                                <p style={{ fontWeight: '700' }}>

                                Date Of Birth  
                                </p> {digilockerDetails?.response?.response?.aadhaar?.dob}
                              </div>
                              <div style={{display:"block"}}>

                                <p style={{ fontWeight: '700' }}>
                                
                                Image 
                                </p> <p style={{cursor:'pointer',textDecoration:'underline',color:'blue'}} onClick={()=>{handleClick(digilockerDetails?.response?.response?.aadhaar?.image)}}>Click to See Image</p>
                              </div>
                          </div>  
                          <div>
                               
                            <div
                              style={{
                                display: 'flex',
                                
                                paddingLeft: '1em',
                                paddingRight: '1em',
                              
                              }}
                            >
                              
                              <div style={{ paddingTop: '1em'}}>
                                <p style={{ fontWeight: '700' }}>
                                  
                                  Permanent Address:
                                  <p style={{width:'20em', fontWeight: '350', marginLeft: '10px' }}>
                                  {digilockerDetails?.response?.response?.aadhaar?.permanent_address} 
                                  <br/>
                                  
                                  </p>
                                </p>
                                
                              </div>

                              <div style={{ paddingTop: '1em',marginLeft:'1em'}}>
                                <p style={{ fontWeight: '700' }}>
                                  
                                  Correspondence Address:
                                  <p style={{ width:'20em',fontWeight: '350', marginLeft: '10px' }}>
                                  {digilockerDetails?.response?.response?.aadhaar?.current_address} 
                                  </p>
                                </p>
                              
                            
                              </div>
                              </div>
                            </div>

                          
                            
                            </div>
             
               
                </div>
      
             </>
           :null}    
               <p style={{border:'1px dashed #C0C0C0'}}></p>
               <p style={{fontWeight:'bold',fontSize:'18px',fontFamily:'Outfit'}}>Documents Obtained</p>

                <div class="slides">


                  
                    {
                      kycDetails?.identityList?.map((item)=>{
                        return(
                          <div className="insideDiv">
                           <img style={{width:'5em',height:'5em',margin:'1.5em'}} src={documentImage}/>
                           <div style={{lineHeight:'1em'}}>
                          <p style={{marginLeft:'2em',fontFamily:'Outfit'}}>{item.name}</p>
                          <p style={{color:'#D32028',marginLeft:'2em'}}>{item.id}</p>
                          </div>
                         </div>
                        )
                      })
                    }
                   
                  
                 
                  </div>
          </div>
        </div>

  </div>
   
  )
}